import {getCookie} from "tiny-cookie";
import {SmartStorage} from "smart-core-util";

export const tenant = {
    data () {
        return {
            proposalId: '',
            supplierId: '',
            eventData: {},
            proposalSupplier: {},
            cancelClause: {},
            taskStatus: 0,
            taskId: '',
        }
    },
    computed: {
    },
    created () {
        this.eventData = this.$SmartStorage.get("eventData");
        if (this.eventData) {
            this.proposalId = this.eventData.proposalId;
        } else {
            this.proposalId = this.$SmartStorage.get("proposalId");
        }
        this.supplierId = this.$SmartStorage.get('supplierId')
        this.taskId = this.$SmartStorage.get('taskId')
        this.taskStatus = this.$SmartStorage.get('taskStatus')
        this.init()
    },
    methods: {
        init () {
            this.GetProposalItems()
            this.getTips()
        },
        async GetProposalItems () {
            let params = {
                SupplierId: this.supplierId,
                ProposalId: this.proposalId,
            }
            let res = await this.$service.GetProposalItems(params)
            if (res && res.success) {
                this.loadType = '';
                this.venueList = []
                this.mealList = []
                this.accommodationList = []
                this.otherList = []
                this.demandList = res.content.items
                this.proposalSupplier = res.content?.proposalSupplier || {}
                this.proposalSupplier.quoteDeadTime = res.content?.proposalSupplier?.quoteDeadTime ? res.content?.proposalSupplier?.quoteDeadTime.replace('T', ' ') : ''
                this.cancelClause = JSON.parse(this.proposalSupplier.cancelClause || '{}')
                res.content.items.forEach((ele, index) => {
                    ele.key = new Date().getTime() + index
                    ele.dtStart = ele.dtStart.replace('T', ' ')
                    ele.dtEnd = ele.dtEnd.replace('T', ' ')
                    switch (ele.itemType) {
                        case 1:
                            this.venueList.push(ele)
                            break
                        case 2:
                            this.mealList.push(ele)
                            break
                        case 3:
                            this.accommodationList.push(ele)
                            break
                        case 4:
                            this.otherList.push(ele)
                            break
                    }
                })
            }
        },
        closeWebview () {
            this.$router.go(-1)
        },
        async getTips () {
            let params = {
                collection: "cfg-ivenue-tips",
                filter: {
                    tenantCode: getCookie('tenant') || SmartStorage.get("tenant") || SmartStorage.get("tenant_code"),
                    origin: 'quotedPrice',
                }
            }
            let res = await this.$service.getsettings(params)
            if (res && res.success) {
                this.tips = res.content ? res.content.content : {}
            }
        },
        fieldCall(data) {
            let container =
                this.$SmartStorage.get("container") || sessionStorage.getItem("container");
            if (data.callBack == "trainingMaterials") {
                switch (container) {
                    case "browser":
                    case "h5":
                        // 直接下载
                        console.log("====文件信息", data);
                        window.open(data.path);
                        break;
                    case "native":
                    default:
                        if (window.flutter_inappwebview) {
                            window.flutter_inappwebview.callHandler(
                                "App.openPdf",data.path
                            );
                        } else {
                            this.iJsBridge.call({
                                method: "SMGeneral.openNewWebSite",
                                postData: {
                                    loadUrl: data.path,
                                    browserMode: {
                                        navBarVisibilty: true,
                                        titleTextColor: "#ffffff",
                                        navBackgroundColor: data.navBackgroundColor,
                                    },
                                },
                            });
                        }

                        break;
                }
            }
        },
    }
}
