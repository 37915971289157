<template>
    <section>
        <div class="venueItem">
            <div style="position: relative">
              <div class="itemDate" v-if="item.itemTypeTxt && ['酒店住宿'].includes(item.itemTypeTxt)">
                {{item.dtStart | formatDateZh('MM月dd日')}} ~ {{item.dtEnd | formatDateZh('MM月dd日')}}
              </div>
              <div class="itemDate" v-else>
                {{item.dtStart | formatDateZh('MM月dd日 hh:mm')}} ~ {{item.dtEnd | formatDateZh('MM月dd日 hh:mm')}}
              </div>
                <div class="itemType" v-if="item.itemType == 1">
                    <span>{{item.itemName}}</span>
                    <span>{{item.qty1}} 人</span>
                    <span>{{ isTear }}</span>
                </div>
                <div class="itemType" v-if="item.itemType == 2">
                    <span>{{item.itemName}}</span>
                    <span>{{item.itsItemData.mealType}}</span>
                    <span>{{item.qty1}} 人</span>
                </div>
                <div class="itemType" v-if="item.itemType == 3">
                    <span>{{item.itemName}}</span>
                    <span>{{item.qty1}} {{item.unit1}}</span>
                    <span>{{item.qty2}} {{item.unit2}}</span>
                </div>
                <div class="itemType" v-if="item.itemType == 4">
                    <span>{{item.itemName}}</span>
                    <span>{{item.qty1}} {{item.unit1}}</span>
                </div>
            </div>
            <div class="itemDescr" v-if="item.descr">
                <p>{{item.descr}}</p>
            </div>
          <div class="itemPrice itemDetails" v-if="item.itsExpenses.length ||  [11,30].includes(status)" :class="isShowDetails ? 'active' : ''">
            <div class="venueAmount">
              <p><span class="amountStyle">￥{{(item.amount || 0).toFixed(2)}}</span></p>
              <p @click="isShowDetails = !isShowDetails" style="display: flex;flex-direction: row;justify-content: center;align-items: center;">
                {{ isShowDetails ? '收起详情' : '展开详情' }}
                <svg class="icon" aria-hidden="true" style="width: .15rem;height: .15rem">
                  <use :xlink:href="isShowDetails ? '#icon-jiantoushang' : '#icon-jiantouxia'"></use>
                </svg>
              </p>
            </div>
            <div v-if="item.itemType == 1 && ([11,30].includes(status) || item.itsExpenses.length)">
              <div v-for="(list, i) in item.itsExpenses" :key="i">
                <div class="venuePrice" v-if="list.costType != '茶歇'">
                  <div>{{list.costType}}</div>
                  <div class="priceColor">{{(list.price || 0) | currencyFormatter}} * {{list.qty}}</div>
                  <div class="priceColor">{{(list.price * list.qty || 0) | currencyFormatter}}</div>
                </div>
                <div class="venueTea" v-else>
                  <div class="teaPrice">
                    <div>{{list.costType}}</div>
                    <div class="priceColor">{{(list.price || 0) | currencyFormatter}} * {{list.qty}}</div>
                    <div class="priceColor">{{(list.price * list.qty || 0) | currencyFormatter}}</div>
                  </div>
                  <div class="teaDescr" v-if="list.itsExpenseData" style="margin-top: -0.07rem;">
                    <div>人均    {{(list.price || 0) | currencyFormatter}}</div>
                    <div>保底数量    {{list.itsExpenseData.minQty}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="[2,3,4].includes(item.itemType) && ([11,30].includes(status) || item.itsExpenses.length)">
              <div v-for="(list, i) in item.itsExpenses" :key="i">
                <div class="venuePrice">
                  <div>{{list.costType}}</div>
                  <div class="priceColor">{{(list.price || 0) | currencyFormatter}} * {{list.qty}}</div>
                  <div class="priceColor">{{(list.price * list.qty || 0) | currencyFormatter}}</div>
                </div>
                <div class="mealDescr" v-if="[2].includes(item.itemType)" style="margin-top: -0.07rem;">
                  <div>人均    {{(list.price || 0) | currencyFormatter}}</div>
                  <div>保底数量    {{list.itsExpenseData.minQty}}</div>
                </div>
              </div>
            </div>
            <div class="goDetails" style="color: #757575;font-size: .15rem;margin-top: .1rem;margin-bottom: -0.1rem;" @click="goDetail">
              更多详情
            </div>
          </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'venueItem',
        props: {
            item: {
                type: Object,
                default () {
                    return {}
                }
            },
            index: {
                type: Number,
                default: 0
            },
            status: {
                type: Number,
                default: 0
            }
        },
        data () {
            return {
              isShowDetails: false,
            }
        },
        computed: {
          isTear() {
            if (
                this.item.itsItemData &&
                this.item.itsItemData.teaBreak &&
                this.item.itsItemData.teaBreak.length > 0 &&
                this.item.itsItemData.teaBreak[0].hasBreak
            ) {
              return "有茶歇";
            } else {
              return "无茶歇";
            }
          }
        },
        methods: {
            goDetail () {
                this.$emit('goDetail', this.item)
            }

        }
    }
</script>
<style lang="scss" scoped>
    @import "venueItem";
</style>
