<template>
    <section>
      <sm_header title='报价列表' :smHleftEvent='closeWebview'></sm_header>
        <div class="publicHead">
          <div class="tips" v-if="tips.tips">
            <p class="tipsTitle">注意事项</p>
            <div>
              {{tips.tips}}
              <p class="fileInfo" @click="fieldCall(tips.file)" v-if="tips.file">{{tips.file.title}}</p>
            </div>
          </div>
        </div>
        <div class="loadmore_container" ref="wrapper" :style="{ height: wrapperHeight + 'px' }">
            <i-scrollBox class="opOrderListScroll" @on-top="scrollTop" :loadType="loadType" @on-bottom="scrollBottom">
                <template v-slot:scrollBox>
                    <div class="venueItemBox" v-if="venueList.length">
                        <div class="itemTitle">酒店会场<span></span></div>
                        <div class="scrollBox">
                            <div class="scrollWidth">
                                <div class="venueItem" v-for="(item, index) in venueList" :key="item.key">
                                    <venue-item @goDetail="goDetail(item)" :item="item" :index="index" :status="proposalSupplier.status"></venue-item>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="venueItemBox" v-if="mealList.length">
                        <div class="itemTitle">酒店用餐<span></span></div>
                        <div class="scrollBox">
                            <div class="scrollWidth">
                                <div class="venueItem" v-for="(item, index) in mealList" :key="item.key">
                                    <venue-item @goDetail="goDetail(item)" :item="item" :index="index" :status="proposalSupplier.status"></venue-item>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="venueItemBox" v-if="accommodationList.length">
                        <div class="itemTitle">酒店住宿<span></span></div>
                        <div class="scrollBox">
                            <div class="scrollWidth">
                                <div class="venueItem" v-for="(item, index) in accommodationList" :key="item.key">
                                    <venue-item @goDetail="goDetail" :item="item" :index="index" :status="proposalSupplier.status"></venue-item>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="venueItemBox" v-if="otherList.length">
                        <div class="itemTitle">酒店其他<span></span></div>
                        <div class="scrollBox">
                            <div class="scrollWidth">
                                <div class="venueItem" v-for="(item, index) in otherList" :key="item.key">
                                    <venue-item @goDetail="goDetail(item)" :item="item" :index="index" :status="proposalSupplier.status"></venue-item>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </i-scrollBox>
            <div class="confirmOfferBox">
                <div class="confirmOfferInfo">
                  价格有效期及资源保留至
                </div>
                <div class="confirmOfferDate">
                  {{(proposalSupplier.quoteDeadTime || '') | formatDate}}
                </div>
                <div class="confirmOfferInfo">
                  取消条款
                </div>
                <div class="confirmOfferDate">
                  <p>{{cancelClause.noFeeDate}}前取消，不收取违约金</p>
                  <p>{{cancelClause.contractAmount}}前取消，按报价的{{cancelClause.expenseRatio}}%收取违约金</p>
                  <p>{{cancelClause.allCost}}前取消，按报价的全额收取违约金</p>
                </div>
                <div class="confirmOfferInfo">
                  其他条款
                </div>
                <div class="confirmOfferDate">
                  {{proposalSupplier.clause}}
                </div>
            </div>
        </div>
      <div class="footerBtnListBox" v-show="isConfirmOffer">
        <p v-for="(item, index) in btnList" :key="index" @click="actionClick(item)">{{item.actName}}</p>
      </div>
      <van-popup v-model="showBottom" position="bottom" :style="{ height: '100%' }">
        <sm_header title='退回重报' :smHleftEvent='() => {showBottom = false}'></sm_header>
        <van-field class="backReason" style="margin-top: .44rem;" v-model="message" rows="5" autosize
                   type="textarea" maxlength="400" placeholder="请输入退回重报原因" show-word-limit />
        <div class="largeBtn6" @click="submitReport">提交</div>
      </van-popup>
    </section>
</template>
<script>
    import {tenant} from './tenant.js'
    import venueItem from "./components/venueItem";
    import iScrollBox from "@/components/iScrollBox/iScrollBox";
    import { Field, Popup} from 'vant';
    export default {
        name: 'requirementsList',
        mixins: [tenant],
        components: {
            venueItem,
            iScrollBox,
            [Field.name]: Field,
            [Popup.name]: Popup,
        },
        data () {
            return {
                demandList: [],
                venueList: [],
                mealList: [],
                accommodationList: [],
                otherList: [],
                show: false,
                showBottom: false,
                reason: '',
                message: '',
                tips: {},
                btnList: [
                    {
                        actName: '不接受',
                        color: '#fb304d',
                        code: 'reject',
                        funName: 'acceptQuotation'
                    },
                    {
                        actName: '退回重报',
                        color: '#ffb52a',
                        code: '',
                        funName: 'rereport'
                    },
                    {
                        actName: '接受报价',
                        color: '#2d82f0',
                        code: 'accept',
                        funName: 'acceptQuotation'
                    }
                ],
                title: '',
                loadType: '',
                pageIndex: 1,
                pageSize: 10,
                total: 0,
                visible: false,
                wrapperHeight: 0,
            }
        },
        mounted() {
            this.wrapperHeight = (document.documentElement.clientHeight || window.innerHeight) - this.$refs.wrapper.getBoundingClientRect().top;
        },
        computed: {
          isConfirmOffer () {
              return this.taskStatus == 0 && [11,12].includes(this.proposalSupplier.status) && this.taskId
          }
        },
        methods: {
            scrollTop() {
                this.loadType = 'top';
                this.pageIndex = 1;
                this.init(true)
            },
            scrollBottom() {
                if (this.pageSize * this.pageIndex >= this.total) {
                    this.loadType = '';
                } else {
                    this.loadType = 'bottom';
                    this.pageIndex += 1;
                    this.init();
                }
            },
            goDetail (item) {
                this.$SmartStorage.set('quotePriceItem', item)
                this.$router.push('/quotedPriceDetails')
            },
            confirmOffer () {
                this.visible = true
            },
            actionClick (item) {
                this[item.funName](item)
            },
            acceptQuotation (item) {
                this.$dialog.confirm({
                    title: '提示',
                    message: item.code == 'accept' ? '是否确认接受本酒店的报价' : '是否确认不接受本酒店的报价',
                }).then(async () => {
                    let params = {
                        proposalId: this.proposalId,
                        supplierId: this.supplierId,
                        operationType: item.code
                    }
                    let response = await this.$service.supplierSelected(params);
                    if (response.success) {
                        if(item.code == 'accept') {
                            this.$toast('接受报价成功')
                        }else{
                            this.$toast('不接受报价成功')
                        }
                        this.$router.back()
                    }
                })
            },
            rereport () {
              this.showBottom = true
              this.message = ''
            },
          async submitReport () {
            let params = {
              proposalId: this.proposalId,
              supplierId: this.supplierId,
              reason: this.message,
              formData: JSON.stringify({supplierId: this.supplierId}),
              taskData: JSON.stringify({rebackReason: this.message}),
              operationType: 2 // 提交 1，退回报价 2，退回结算3，退回损失 4
            }
            let response = await this.$service.createTask(params);
            if (response.success) {
              this.$toast('退回报价成功')
              this.$router.back()
            }
          }
        }
    }
</script>
<style scoped lang="scss">
    @import "quotedPriceDemandList";
</style>
